// lib/analytics.js
class Analytics {
  constructor() {
    if (Analytics.instance) {
      // eslint-disable-next-line no-constructor-return
      return Analytics.instance;
    }

    this.ssrEvent = null;
    this._hasPushedSSREvent = false;

    Analytics.instance = this;
  }

  pushEvent(eventData) {
    if (!this.ssrEvent && eventData.page.load === 'server') {
      this.ssrEvent = eventData;
    } else {
      if (this.ssrEvent && !this._hasPushedSSREvent) {
        window.dataLayer.push(this.ssrEvent);
        this._hasPushedSSREvent = true;
        this.ssrEvent = null;
      }
      window.dataLayer.push(eventData);
    }
  }

  sendPageview(eventPayload) {
    this.pushEvent({ event: 'pageview', ...eventPayload });
  }
}

const analyticsInstance = new Analytics();

export default analyticsInstance;
